
export const USER_PROFILE='api/user-profile'
export const LOGOUT_API= '/api/logout'
export const AUTH_API= '/api/auth-status'

export const SITE_SETTINGS='api/site-settings';
export const AUTH_STATUS='api/auth-status';
export const GET_FAVOURITE_LIST='api/favorite-list';
export const WALLET_BALANCE='wallet';
export const AUTH_REFRESH='api/auth-refresh';
export const CAPTCHA_API='api/v1/captcha';
export const GET_SPORTS= 'v1/sports/management/getSport';
export const MATCHES_BY_SPORT_ID = 'v1/events/matches/';
export const EVENTS_SPORTS_ALL='v1/events/sports/all';
export const SEARCH='api/search';
export const GET_WALLET_GETWAY='api/get-payment-getway-iframe-url';
export const DEMO_LOGIN_API='api/demo-user-login';
