<template>
    <!-- Modal -->


    <div class="modal-content">
        <div class="modal-header">
            <div class="game-header-content">
                <div class="game-bak-close-block">
                    <strong class="video-title">{{ title }}</strong>
                </div>
                <div class="close-game-btn">
                    <a href="javascript:void(0);" data-bs-dismiss="modal"><img src="@/assets/images/red-close-btn.webp"
                            alt=""></a>
                </div>
            </div>
        </div>
        <div class="modal-body">
            <div class="game-iframe-sec">
                <div class="iframe-content">
                    <video style="width: 100%;" height="240" controls autoplay>
                        <source
                            :src="link"
                            type="video/mp4">
                    </video>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "VideoModal",
    props: ['title', 'link'],
    mounted(){
        console.log("Video component...")
    }
}
</script>

<style scoped>
.video-title{
    color: var(--primary-color);
}
.game-header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
</style>