<template>
    <!-- login up Modal -->
    <div class="modal login-modal-box" id="login" tabindex="-1" aria-labelledby="exampleModalLabel"
        aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <div v-if="siteSettings" class="modal-logo">
                        <img :src="siteSettings?.aws_url +
                        siteSettings?.storage_path?.domain_image +
                        siteSettings?.logo
                        " alt="logo.webp" loading="lazy" />
                    </div>
                    <button type="button" @click="refreshData()" ref="close_btn" class="btn-close" data-bs-dismiss="modal"
                        aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <form class="registration-form">
                        <div class="login-tab-header">
                            <ul class="nav nav-tabs" id="myTab" role="tablist">
                                <li class="nav-item" role="presentation">
                                    <button class="nav-link active" id="user-tab" data-bs-toggle="tab"
                                        data-bs-target="#user" type="button" role="tab" aria-controls="user"
                                        aria-selected="true"><img src="@/assets/images/user-tab.webp">{{translatedLangData('by-user-id', 'By user id') }}</button>
                                </li>
                                <li v-if="siteSettings?.business_type == 2" class="nav-item" role="presentation">
                                    <button class="nav-link" id="mobile-tab" data-bs-toggle="tab"
                                        data-bs-target="#mobile" type="button" role="tab" aria-controls="mobile"
                                        aria-selected="false"><img src="@/assets/images/otp.webp"> {{
                        translatedLangData('by-phone-number', 'By phone number') }}</button>
                                </li>
                            </ul>
                        </div>
                        <div class="tab-content" id="myTabContent">
                            <div class="tab-pane fade show active" id="user" role="tabpanel" aria-labelledby="user-tab">
                                <div class="form-group">
                                    <div class="col-12">
                                        <div class="col-auto">
                                            <input type="text" v-model="form.userId" class="form-control"
                                                :placeholder="translatedLangData('user-name', 'Username')" />
                                        </div>
                                    </div>
                                    <span v-if="showValidationError && getId() == ''" class="text-danger">{{
                        translatedLangData('please-enter-user-id', 'Please enter user id') }}</span>
                                </div>

                            </div>
                            <div class="tab-pane fade" id="mobile" role="tabpanel" aria-labelledby="mobile-tab">
                                <div class="row form-group">
                                    <div class="col-3">
                                        <div class="col-auto">
                                            <select v-model="form.code" class="form-select"
                                                aria-label="Default select example" name="countrycodewhatsapp"
                                                id="countrycodewhatsapp">
                                                <option :value="allowedCountry.phonecode" v-for="(
                                      allowedCountry, countryIndex
                                    ) in siteSettings?.country_code_list" :key="countryIndex">
                                                    +{{ allowedCountry.phonecode }}
                                                </option>
                                            </select>

                                        </div>
                                    </div>
                                    <div class="col-9">
                                        <div class="col-auto">
                                            <input type="number" v-model="form.mobileNo" class="form-control"
                                                placeholder="Mobile No." />
                                        </div>
                                    </div>
                                    <span v-if="showValidationError && getId() == ''" class="text-danger">{{
                        translatedLangData('please-enter-mobile-number', 'Please enter mobile number')}}</span>
                                </div>


                            </div>
                        </div>

                        <div class="row form-group">
                            <div class="col-12">
                                <div class="col-auto password-field">
                                    <input v-model="form.password" class="form-control pass_log_id"
                                        :type="form.passwordVisible ? 'text' : 'password'" name="pass"
                                        :placeholder="translatedLangData('password', 'password')" value="">
                                    <span @click="form.passwordVisible = !form.passwordVisible"
                                        v-if="form.passwordVisible"
                                        class="bi bi-eye-fill field_icon toggle-password"></span>
                                    <span @click="form.passwordVisible = !form.passwordVisible" v-else
                                        class="bi bi-eye-slash-fill field_icon toggle-password"></span>
                                </div>
                            </div>
                            <span v-if="showValidationError && form.password == ''" class="text-danger">{{
                        translatedLangData('please-enter-password', 'Please enter password') }}</span>
                        </div>
                        <div class="row form-group">
                            <div class="col-12">
                                <div class="col-auto forgot-pass">
                                    <a @click="redirectPage('/forget-password')">{{ translatedLangData('forgot-password', 'Forgot Password')}}?</a>
                                </div>
                            </div>
                        </div>
                        <div class="row form-group">
                            <div class="col-12">
                                <div class="col-auto">
                                    <a @click="signInMethodCall()" class="btn gradient-btn"
                                        :class="{ 'btn-loading': loading }">
                                        {{ translatedLangData('login', 'Login') }}
                                        <span><b></b><b></b><b></b></span>
                                    </a>
                                    <a @click="demoUserLogin()" class="btn gradient-btn"
                                        :class="{ 'btn-loading': demoLoading }">
                                        {{ translatedLangData('demo-sign-in', 'Demo Sign In') }}
                                        <span><b></b><b></b><b></b></span>
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div class="row form-group">
                            <div class="col-12">
                                <div class="col-auto">
                                    <h3 class="whats-with"><span>{{ translatedLangData('or-login-with', 'or Login with')}}</span></h3>
                                    <div class="social-login-icon">
                                        <a><img src="@/assets/images/facebook-icon.webp"></a>
                                        <a><img src="@/assets/images/google-icon.webp"></a>
                                        <a><img src="@/assets/images/instagram-icon.webp"></a>
                                        <a><img src="@/assets/images/telegram-icon.webp"></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-if="siteSettings && siteSettings?.social_link &&
                        siteSettings?.social_link?.whatsappno1
                        " class="row form-group">
                            <div class="col-12">
                                <div class="col-auto">
                                    <h3 class="whats-with">{{ translatedLangData('or-continue-with', 'Or Continue With')}} <span class="whats-with-now"> {{
                                            translatedLangData('whatsapp','Whatsapp')}}</span></h3>
                                    <div class="button-whatsapp">
                                        <a :href="'//wa.me/' + siteSettings?.social_link?.whatsappno1
                                                            " target="_blank"><button type="button"
                                                class="btn-whatsapp"><img src="@/assets/images/Whatsapp.webp" alt="" /> {{
                                                translatedLangData('whatsapp-now','Whatsapp Now')}}</button></a>
                                    </div>
                                    <p class="whats-with-acc">
                                        <a @click="redirectPage('/sign-up')"> {{translatedLangData('create-new-account','Create New Account')}}</a></p>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>


</template>

<script>
import { AUTH_TOKEN } from "@/shared/constants/cookies-const";
import api from "../services/api";
import * as apiName from "../services/urls";
import axios from "axios";
import { setHeaders } from "@/shared/services/headers";
import { mapGetters } from "vuex";
import { Modal } from "bootstrap";
import VideoModal from "@/shared/components/modal/VideoModal.vue";

export default {
    name: "Login",
    inject: ['recaptcha', 'translatedLangData'],
    computed: {
        ...mapGetters({
            siteSettings: "siteSettings",
        }),
    },
    data() {
        return {
            form: {
                code: "91",
                mobileNo: "",
                userId: "",
                password: "",
                passwordVisible: false,
                type: 1,
                captchaText: "",
            },
            styleObject: {
                display: "none",
            },
            showValidationError: false,
            loading: false,
            demoLoading: false,
            modal: null,
            choosedUserJourney: { link: null, title: null },
            showUserJourneyVideo: false,
        };
    },
    mounted() {
        if (this.$route.name == "login" && !this.checkIsLogin()) {
            let loginModal = new Modal("#login");
            loginModal.show();
        } else if (this.$route.name == "login" && this.checkIsLogin()) {
            this.$router.push("/");
        }
    },
    methods: {
        refreshData() {
            (this.form = {
                code: "91",
                mobileNo: "",
                userId: "",
                password: "",
                type: 1,
                captchaText: "",
            }),
                (this.showValidationError = false);
        },
        demoUserLogin() {
            this.form.type = 1;
            this.form.userid = "demouser";
            this.form.password = "demopass";
            this.loginServiceCall(apiName.DEMO_LOGIN_API, "demo");
        },
        signInMethodCall() {
            debugger;
            if (!this.loading) {
                let id = this.getId();
                if (id == "" || this.form.password == "") {
                    this.showValidationError = true;
                } else {
                    this.loginServiceCall(apiName.LOGIN_API, "nondemo");
                }
            }
        },
        chooseUserJourney(userJourneLink, title) {
            if (userJourneLink) {
                userJourneLink = this.siteSettings?.aws_url + this.siteSettings?.storage_path.user_journey_path + userJourneLink;
            }
            this.choosedUserJourney = { link: userJourneLink, title: title };
            //this.$bvModal.show('user_journey');
            this.showUserJourneyVideo = true;
            this.$refs.show_user_journey_video.click();
        },
        async loginServiceCall(url, type) {
            let id = this.getId();
            if (type == "demo") {
                this.demoLoading = true;
            } else {
                this.loading = true;
            }
            let recaptchaToken = await this.recaptcha();
            let data = {
                userid: id.toString(),
                type: this.siteSettings?.business_type == 1 ? 2 : this.form.type,
                password: this.form.password,
                recaptcha_token: recaptchaToken,
                cncode: this.form.code,
            };

            api
                .post(url, data)
                .then((response) => {
                    if (type == "demo") {
                        this.demoLoading = false;
                    } else {
                        this.loading = false;
                    }
                    if (response) {
                        if (response.status == 200) {
                            if (response?.data?.status == 0) {
                                this.showErrorModalFunc(response.data.debug[0]);
                            } else {
                                this.showSuccessModalFunc(response.data.message);
                                let userData = response.data.data;
                                this.$cookies.set(AUTH_TOKEN, userData.token);
                                this.$store.dispatch("setToken", `Bearer ${userData.token}`);
                                window.authorization_token = `Bearer ${this.$cookies.get(
                                    AUTH_TOKEN
                                )}`;
                                //for add on all APIs
                                axios.defaults.headers.common[
                                    "Authorization"
                                ] = `Bearer ${this.$cookies.get(AUTH_TOKEN)}`;
                                setHeaders();
                                this.setUserDataToStore(userData);
                                this.refreshData();
                                //this.$router.go();
                                location.reload();
                                this.closeModal();
                            }
                        }
                    }
                })
                .catch((error) => {
                    console.log("Error Login : ", error);
                    if (type == "demo") {
                        this.demoLoading = false;
                    } else {
                        this.loading = false;
                    }
                    if (error) {
                        this.showErrorModalFunc(error[0]);
                    }
                });
        },
        clickOnType(type) {
            this.form.type = type;
            this.styleObject.display = "none";
            this.form.userId = "";
            this.form.mobileNo = "";
        },
        getId() {
            if (this.siteSettings?.business_type == 2) {
                return this.form.type == 1 ? this.form.mobileNo : this.form.userId;
            } else {
                return this.form.userId;
            }
        },
        showErrorModalFunc(message) {
            this.$emit("error-modal", message);
        },
        showSuccessModalFunc(message) {
            this.$emit("success-modal", message);
        },
        closeModal() {
            this.$refs.close_btn.click();
        },
        checkIsLogin() {
            return this.$store?.getters?.isAuthenticated;
        },
        setUserDataToStore(userData) {
            let user = {
                balance: userData?.balance,
                currency: userData.currency,
                currency_slug: userData.currency_slug,
                last_login_at: userData.last_login_at,
                name: userData.name,
                role_id: userData.role_id,
                userid: userData.userid,
                exposure: userData.exposure,
                available_credits: userData.available_credits,
                id: userData.id,
                exp: userData.exp,
            };
            this.$store.dispatch("setUser", user);
            this.$store.dispatch("setChips", userData.chips);
            this.$store.dispatch("setEventManage", userData.event_manage);
        },

        redirectPage(pageName){
            this.refreshData();
            this.closeModal();
            this.$router.push({path:pageName})
        }
    },
    components: { VideoModal },
};
</script>

<style scoped>
div.mm-dropdown ul li {
    display: flex !important;
    padding-left: 10px;
}
</style>