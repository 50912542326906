<template>
        <div class="modal-dialog" :class="{'d-none' : !siteRuleData}">
            <div class="modal-content">
                <div class="modal-header">
                    <div class="thm-heading">
                        <h3>{{ translatedLangData('rules-results','Rules & Results')}}</h3>
                    </div>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body rule-height">
                    <div class="back-modal">
                        <div class="language-list d-block" v-html="siteRuleData">
                        </div>
                    </div>
                </div>
                <div class="back-modal-footer ">
                    <button type="button" class="btn btn-primary place-but w-100" data-bs-dismiss="modal">{{ translatedLangData('close','Close')}}</button>
                </div>
            </div>
        </div>
</template>
<script>
    export default {
        name: "RulesModal",
        props: ['siteRuleData'],
        inject:['translatedLangData'],
        data() {
            return {}
        },
    };
</script>
<style scoped>
    .rule-height {
        height: 36.6rem;
        overflow-y: auto;
    }
</style>