<template>
    <!-- <div class="footer-page">
        <span class="bottom-image"></span>
        <div class="footer-item">
            <ul>
                <li>
                    <router-link to="/" :class="{ 'active': $route.name == 'Home' }">
                        <div class="list">
                            <div class="footer-img">
                                <img src="@/assets/images/home.svg" alt="" loading="lazy" />
                            </div>
                            <div class="footer-title-text">
                                <span>{{ translatedLangData('home', 'Home') }}</span>
                            </div>
                        </div>
                    </router-link>
                </li>
                <li>
                    <router-link to="/sports/inplay/0" :class="{ 'active': $route.name == 'sports' }">
                        <div class="list">
                            <div class="footer-img">
                                <img src="@/assets/images/sports.webp" alt="" loading="lazy" />
                            </div>
                            <div class="footer-title-text">
                                <span>{{ translatedLangData('sports', 'Sports') }}</span>
                            </div>
                        </div>
                    </router-link>
                </li>
                <li v-if="siteSettings?.social_link?.whatsappno1">
                    <a :href="'//wa.me/' + siteSettings?.social_link?.whatsappno1" target="_blank">
                        <div class="list bottom-tab">
                            <div class="footer-center-img">
                                <img src="@/assets/images/Whatsapp-img.webp" alt="" loading="lazy" />
                            </div>
                        </div>
                    </a>
                </li>
                <li>
                    <router-link to="/games/LIVE_CASINO" :class="{ 'active': $route.name == 'games' }">
                        <div class="list">
                            <div class="footer-img">
                                <img src="@/assets/images/casino.webp" alt="" loading="lazy" />
                            </div>
                            <div class="footer-title-text">
                                <span>{{ translatedLangData('casino', 'Casino') }}</span>
                            </div>
                        </div>
                    </router-link>
                </li>
                <li>
                    <a @click="clickOnAccountButton()" type="button">
                        <div class="list">
                            <div class="footer-img">
                                <img src="@/assets/images/profill-img.webp" alt="" loading="lazy" />
                            </div>
                            <div class="footer-title-text">
                                <span>{{ checkIsLogin() ? userData?.userid : translatedLangData('sign-in', 'Sign In') }}</span>
                            </div>
                        </div>
                    </a>
                </li>
            </ul>
        </div>
    </div> -->

    <template v-if="$route.name == 'Home'">
      

        <div class="footer-fixed">
            <div class="container">
                <ul class="menu-btm">
                    <li><router-link :class="{'active':$route.name=='Home'}" to="/"><span><img
                                    src="@/assets/images/home-yellow.webp"></span>Home</router-link>
                    </li>
                    <li><router-link :class="{'active':$route.name=='games'}" to="/games"><span><img src="@/assets/images/Casino-menu.webp"></span>Casinos</router-link></li>
                    <li><router-link to="/inplay-sports" class="whatsup-icon"><span><img
                                    src="@/assets/images/cricket-fight-icon.webp"></span></router-link></li>
                    <li><a @click="clickOnMyMarketButton()"><span><img src="@/assets/images/betting.webp"></span>Markets</a></li>
                    <li><a href="javascript:void(0)" @click="clickOnAccountButton()"><span><img
                                    src="@/assets/images/More22.webp"></span>Menu</a></li>
                </ul>
            </div>
        </div>


        <div class="whatsup-fixed-icon">
            <div class="container">
                <div class="instant_id_row">
                    <a target="_blank"
                        class="in-image2 create-whatsapp float2 whatsAppSettingsAfterLogin2 signup_btn_wrapper ng-star-inserted">
                        <div class="wa-img">
                            <img src="@/assets/images/whatsapp12.webp">
                        </div>
                        <button><span class="blink">Whatsapp for new ID</span></button>
                    </a>
                </div>
            </div>
        </div>

        <div class="top-scroll" id="return-to-top">
            <div class="container">
                <a href="javascript:"><i class="fa-solid fa-angle-up"></i></a>
            </div>
        </div>

        <div class="support-chat">
            <div class="container">
                <a href="javascript:"><img src="@/assets/images/Live-ChatFill.webp"></a>
            </div>
        </div>
    </template>

    <template v-else>
        <div class="bottom-nav-container">
            <div class="flex-bottom">
                <div class="button-outer">
                    <router-link v-if="siteSettings" to="/">
                        <img style="height: 40px;" :src="siteSettings?.aws_url + siteSettings?.storage_path?.domain_image + siteSettings?.logo"
                            alt="logo" class="logo-class">
                    </router-link>
                    <div class="walletbg inner-liability">
                        <p>Balance: <span class="balance">  {{ userData?.balance }}</span></p>
                        <p>Liability : <span class="liability"> 0 </span></p>
                    </div>
                </div>
                <div class="button-outer d-flex right-portion">
                    <span><a @click="$router.back()"><img src="@/assets/images/arrowBottom.webp"
                                class="btn-nav-btm"></a></span>
                    <span class="mlt-10"><img class="btn-nav-btm" @click="clickOnAccountButton()"
                            src="@/assets/images/menuicon.webp"></span>
                </div>
            </div>
        </div>
    </template>

    <div ref="open_account_menu" style="display: none;" data-bs-toggle="modal" data-bs-target="#offcanvasExample"
        aria-controls="offcanvasExample"></div>

    <div ref="open_login_modal" style="display: none;" data-bs-toggle="modal" data-bs-target="#login">
    </div>

</template>




<script>
import ChatComponent from '../chat/ChatComponent.vue';

export default {
    name: "Footer",
    emits: ['error-modal', 'success-modal'],
    inject: ['translatedLangData'],
    components: {
        ChatComponent
    },
    computed: {
        siteSettings() {
            return this.$store.getters.siteSettings;
        },
        userData() {
            return this.$store.getters.stateUser;
        }
    },
    mounted() {
        console.log("User Data : ", this.userData)
    },
    methods: {
        clickOnAccountButton() {
            if (this.checkIsLogin()) {
                this.$refs.open_account_menu.click();
            }
            else {
                this.$refs.open_login_modal.click();
            }
        },

        clickOnMyMarketButton() {
            if (this.checkIsLogin()) {
                this.$router.push({ name: 'my-market' });
            }
            else {
                this.$refs.open_login_modal.click();
            }
        },
        checkIsLogin() {
            return this.$store.getters.isAuthenticated;
        }
    }
};
</script>

<style scoped>
.footer-list-sec {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
</style>