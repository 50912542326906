<template>
    <div class="main-container">
        <div class="main-row top-navbar-container">
            <marquee class="MarqueeTxt" style="margin-right: 6px;">
                <p> 👉👉GET 3% REFILL BONUS ON EVERY DEPOSIT FOR DEPOSIT CONTACT ON YOUR DEPOSIT AGENCY NUMBER THROUGH
                    WHATSAPP👈👈"To VERIFY YOUR BRANCH DEPOSIT/WITHDRAW NUMBER. CONTACT HELP DESK NO.+91 7741000000</p>
            </marquee>
        </div>
    </div>
</template>
<script>
export default {
    name: 'NewsSection'
}
</script>