<template>
    <!-- <div class="my-account-menu">
        <div class="offcanvas offcanvas-bottom" tabindex="-1" id="offcanvasExample" aria-labelledby="offcanvasExampleLabel">
            <div class="offcanvas-header">
                <button ref="close_button" type="button" class="close" data-bs-dismiss="offcanvas"
                    aria-label="Close">x</button>
                <div class="menu-content">
                    <div class="thm-heading">
                        <h3>{{ this.userData?.userid }}</h3>
                        <p>{{ translatedLangData('last-login','Last Login')}}: {{ this.userData?.last_login_at }}</p>
                    </div>
                </div>
            </div>
            <div class="offcanvas-body">
                <div class="mene-details">
                    <div class="menu-details-list">
                        <ul>
                           
                            <li>
                                <a  class="w-100">
                                    <div class="wallet-balance-sec">
                                        <div class="menu-details-heading">
                                            <h4>{{ translatedLangData('wallet-balance','Wallet balance')}}</h4>
                                        </div>
                                        <div class="menu-details-heading">
                                            <h4><span class="profit-color border-0">₹ {{ this.userData?.balance
                                            }}</span></h4>
                                        </div>
                                    </div>
                                </a>
                            </li>
                            <li>
                                <div class="menu-details-heading">
                                    <h4>{{ translatedLangData('exposure','Exposure')}}</h4>
                                </div>
                                <div class="menu-details-heading">
                                    <h4><span style="color: #f44949;">₹ {{ this.userData?.expl }}</span></h4>
                                </div>
                            </li>
                            <li class="border-0">
                                <div class="menu-details-heading">
                                    <h4>{{ translatedLangData('bonus-information','Bonus Information')}}</h4>
                                </div>
                                <div class="menu-details-heading">
                                    <h4>
                                        <span class="information-icon">
                                            <router-link @click="closeModal()" to="/bonus"> <img src="@/assets/images/info-icons.webp" alt=""
                                                    loading="lazy" /></router-link>
                                        </span>
                                    </h4>
                                </div>
                            </li>
                            <li>
                                <div class="bonus-sec">
                                    <div class="credits-list">
                                        <div class="credits-list-con">
                                            <h5>{{ translatedLangData('earn-bonus','Earn Bonus')}}</h5>
                                            <h6>₹ 8000</h6>
                                        </div>
                                        <div class="credits-list-con">
                                            <a href="#locked-bonus-modal"  data-bs-toggle="modal" style="box-shadow: none;">
                                                <h5>{{ translatedLangData('locked-bonus','Locked Bonus')}}</h5>
                                                <h6>₹ 400</h6>
                                            </a>
                                        </div>
                                    </div>
                                    <div class="credits-chackn-box">
                                        <div class="menu-details-heading">
                                            <h4>
                                                {{ translatedLangData('welcome-bonus','Welcome Bonus')}} <span class="information-icon"  data-bs-toggle="modal"
                                                    data-bs-target="#informationModal"> <img
                                                        src="@/assets/images/info-icons.webp" alt="" loading="lazy" /></span>
                                            </h4>
                                        </div>
                                        <div class="menu-heading-con welcome-bonus">
                                            <strong>₹ 500</strong>
                                        </div>
                                    </div>
                                    <div class="credits-chackn-box">
                                        <div class="menu-details-heading">
                                            <h4>{{ translatedLangData('play-with-bonus','Play with Bonus')}}</h4>
                                        </div>
                                        <div class="menu-heading-con">
                                            <div class="form-check form-switch m-0 p-0">
                                                <input class="form-check-input" type="checkbox" id="settingCheckDefaults" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="menu-list">
                    <ul>
                        <li>

                            <div class="language-list">
                                <div class="menu-con">
                                    <div class="menu-icon">
                                        <img src="@/assets/images/language-list-icon.webp" alt="" loading="lazy" />
                                    </div>
                                    <div class="menu-icon-con">
                                        <a data-bs-target="#language_selection_pop_up" data-bs-toggle="modal"
                                            class="notify-item">
                                            <h5>{{ translatedLangData('language','Language')}}</h5>
                                        </a>
                                    </div>
                                </div>
                            </div>

                        </li>
                        <li>
                            <router-link @click="closeModal()" to="/wallet">
                                <div class="menu-con">
                                    <div class="menu-icon">
                                        <img src="@/assets/images/wallet.webp" alt="" loading="lazy" />
                                    </div>
                                    <div class="menu-icon-con">
                                        <h5>{{ translatedLangData('wallet','Wallet')}}</h5>
                                    </div>
                                </div>
                            </router-link>
                        </li>
                        <li>
                            <router-link @click="closeModal()" to="/notifications">
                                <div class="menu-con">
                                    <div class="menu-icon">
                                        <img src="@/assets/images/notification.webp" alt="" loading="lazy" />
                                    </div>
                                    <div class="menu-icon-con">
                                        <h5>{{ translatedLangData('notification','notification')}}</h5>
                                    </div>
                                </div>
                            </router-link>
                        </li>
                        <li>
                            <router-link @click="closeModal()" :to="'/bets/' + types.PAGE_TYPE.USER_PROFILE">
                                <div class="menu-con">
                                    <div class="menu-icon">
                                        <img src="@/assets/images/account-icon.webp" alt="" loading="lazy" />
                                    </div>
                                    <div class="menu-icon-con">
                                        <h5>{{ translatedLangData('profile','Profile')}}</h5>
                                    </div>
                                </div>
                            </router-link>
                        </li>

                        <li>
                            <a @click="getWalletGateway()">
                                <div class="menu-con">
                                    <div class="menu-icon">
                                        <img src="@/assets/images/slidee-3.webp" alt="" loading="lazy" />
                                    </div>
                                    <div class="menu-icon-con">
                                        <h5>{{ translatedLangData('withdraw','Withdraw')}}</h5>
                                    </div>
                                </div>
                            </a>
                        </li>
                        <li>
                            <router-link @click="closeModal()" :to="'/bets/' + types.PAGE_TYPE.OPEN_BETS">
                                <div class="menu-con">
                                    <div class="menu-icon">
                                        <img src="@/assets/images/slide-6.webp" alt="" loading="lazy" />
                                    </div>
                                    <div class="menu-icon-con">
                                        <h5>{{ translatedLangData('open-bets','Open Bets')}}</h5>
                                    </div>
                                </div>
                            </router-link>
                        </li>

                        <li>
                            <router-link @click="closeModal()" :to="'/bets/' + types.PAGE_TYPE.CHIP_SETTING">
                                <div class="menu-con">
                                    <div class="menu-icon">
                                        <img src="@/assets/images/slide-7.webp" alt="" loading="lazy" />
                                    </div>
                                    <div class="menu-icon-con">
                                        <h5> {{ translatedLangData('chip-setting','Chip Setting')}}</h5>
                                    </div>
                                </div>
                            </router-link>
                        </li>
                        <li>
                            <router-link @click="closeModal()" to="/refer-earn">
                                <div class="menu-con">
                                    <div class="menu-icon">
                                        <img src="@/assets/images/bonus-icons.webp" alt="" loading="lazy" />
                                    </div>
                                    <div class="menu-icon-con">
                                        <h5>{{ translatedLangData('refer-and-earn','Refer and Earn')}}</h5>
                                    </div>
                                </div>
                            </router-link>
                        </li>
                        <li>
                            <router-link @click="closeModal()" :to="'/bets/' + types.PAGE_TYPE.ACCOUNT_STATEMENT">
                                <div class="menu-con">
                                    <div class="menu-icon">
                                        <img src="@/assets/images/profit-loss-icon.webp" alt="" loading="lazy" />
                                    </div>
                                    <div class="menu-icon-con">
                                        <h5>{{ translatedLangData('account-statement','Account Statement')}}</h5>
                                    </div>
                                </div>
                            </router-link>
                        </li>
                        <li>
                            <router-link @click="closeModal()" :to="'/bets/' + types.PAGE_TYPE.SETTLED_BETS">
                                <div class="menu-con">
                                    <div class="menu-icon">
                                        <img src="@/assets/images/settled-icons.webp" alt="" loading="lazy" />
                                    </div>
                                    <div class="menu-icon-con">
                                        <h5>{{ translatedLangData('settled-bets','Settled Bets')}}</h5>
                                    </div>
                                </div>
                            </router-link>
                        </li>
                        <li>
                            <router-link @click="closeModal()" :to="'/bets/' + types.PAGE_TYPE.PROFIT_LOSS">
                                <div class="menu-con">
                                    <div class="menu-icon">
                                        <img src="@/assets/images/profitloss.webp" alt="" loading="lazy" />
                                    </div>
                                    <div class="menu-icon-con">
                                        <h5>{{ translatedLangData('profit-loss','profit/loss')}}</h5>
                                    </div>
                                </div>
                            </router-link>
                        </li>

                        <li>
                            <a data-bs-toggle="modal" href="#changepasswordModal">
                                <div class="menu-con">
                                    <div class="menu-icon">
                                        <img src="@/assets/images/change-password-icon.webp" alt="" loading="lazy" />
                                    </div>
                                    <div class="menu-icon-con">
                                        <h5>{{ translatedLangData('change-password','Change Password')}}</h5>
                                    </div>
                                </div>
                            </a>
                        </li>
                        <li class="logout-sec">
                            <a data-bs-toggle="modal" data-bs-target="#logoutmodal">
                                <div class="menu-con">
                                    <div class="menu-icon">
                                        <img src="@/assets/images/logout-icon.webp" alt="" loading="lazy" />
                                    </div>
                                    <div class="menu-icon-con">
                                        <h5>{{ translatedLangData('logout','Logout')}}</h5>
                                    </div>
                                </div>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div> -->


    <div class="modal fade menu-category-modal" id="offcanvasExample" tabindex="-1" aria-labelledby="menuItemLabel"
        aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <div class="header-top">
                        <div class="back-button d-flex justify-content-center align-content-center"
                            data-bs-dismiss="modal" ref="close_button" aria-label="Close">
                            <img src="@/assets/images/back.webp">
                        </div>
                        <div class="innerdiv">
                            <div class="col-cls">
                                <p class="b-title">{{ translatedLangData('balance', 'Balance') }}</p>
                                <p class="b-balance">₹{{ userData?.balance }}</p>
                            </div>
                            <div class="col-cls">
                                <p class="b-title">{{ translatedLangData('liability', 'Liability') }}</p>
                                <p class="b-lib">₹0</p>
                            </div>
                            <a class="btn thm-btn" @click="getWalletGateway()">
                                {{ translatedLangData('deposit', 'Deposit') }}
                            </a>
                        </div>
                    </div>
                </div>
                <div class="modal-body">
                    <div class="user-profile-wrapper">
                        <div class="user-login-title">
                            <div class="user-login-dflex">
                                <div class="menu-heading-con">
                                    <h4>{{ this.userData?.userid }}</h4>
                                    <p>{{ translatedLangData('last-login', 'Last Login') }}: {{
                                    this.userData?.last_login_at }}</p>
                                </div>
                                <div class="menu-heading-con">
                                    <div class="form-check form-switch">
                                        <div v-if="clickBetCheck && getClickBetSelectedValue()" data-bs-toggle="modal"
                                            data-bs-target="#clickBetValue">
                                            <label>{{ getKFormatter(getClickBetSelectedValue()) }}</label>
                                        </div>

                                        <input class="form-check-input" v-model="clickBetCheck"
                                            @change="betCheckChangeFunc()" type="checkbox" id="settingCheckDefaults">
                                        <label class="form-check-label" for="settingCheckDefaults">{{
                                    translatedLangData('on-click-bet', 'On click bet') }}</label>

                                        <a style="display: none;" ref="open_click_bet_value" data-bs-toggle="modal"
                                            data-bs-target="#clickBetValue"></a>
                                    </div>
                                </div>
                            </div>
                            <div class="body-menu-category">
                                <ul>
                                    <li><a href="#"><span class="profit">₹ {{ this.userData?.balance }}</span>{{
                                    translatedLangData('wallet-balance', 'Wallet balance') }}</a></li>
                                    <li><a href="#"><span class="loss">₹ {{ this.userData?.expl }}</span>{{
                                    translatedLangData('exposure', 'Exposure') }}</a></li>
                                </ul>
                            </div>
                            <div class="bonus-sec">
                                <div class="credits-chackn-box">
                                    <div class="menu-details-heading">
                                        <h4>
                                            <router-link @click="closeModal()" to="/bonus">
                                                {{ translatedLangData('welcome-bonus', 'Welcome Bonus') }}
                                                <span class="information-icon" data-bs-toggle="modal"
                                                    data-bs-target="#informationModal"> <img
                                                        src="@/assets/images/information-button.webp" alt=""></span>
                                            </router-link>
                                        </h4>
                                    </div>
                                    <div class="menu-heading-con welcome-bonus">
                                        <strong>₹ 500</strong>
                                    </div>
                                </div>
                                <div class="credits-list">
                                    <div class="credits-list-con">
                                        <h5>{{ translatedLangData('earn-bonus', 'Earn Bonus') }}</h5>
                                        <h6>₹ 8000</h6>
                                    </div>
                                    <div class="credits-list-con">
                                        <a href="#locked-bonus-modal" data-bs-toggle="modal">
                                            <h5>{{ translatedLangData('locked-bonus', 'Locked Bonus') }}</h5>
                                            <h6>₹ 400</h6>
                                        </a>
                                    </div>
                                </div>

                                <div class="credits-chackn-box">
                                    <div class="menu-details-heading">
                                        <h4>
                                            <router-link @click="closeModal()" to="/bonus">
                                                {{ translatedLangData('bonus-information', 'Bonus Information') }}
                                                <span class="information-icon">
                                                    <img src="@/assets/images/information-button.webp" alt="">
                                                </span>
                                            </router-link>
                                        </h4>
                                    </div>
                                </div>
                                <div class="credits-chackn-box">
                                    <div class="menu-details-heading">
                                        <h4>{{ translatedLangData('pla-with-bonus', 'Play with Bonus') }}</h4>
                                    </div>
                                    <div class="menu-heading-con">
                                        <div class="form-check form-switch m-0 p-0">
                                            <input class="form-check-input" type="checkbox" id="settingCheckDefaults">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="body-menu-category">
                        <ul>
                            <li><router-link @click="closeModal()" to="/"><span><img
                                            src="@/assets/images/homeMenuIcons.webp"></span>{{
                                    translatedLangData('home', 'Home') }}</router-link>
                            </li>
                            <li><router-link @click="closeModal()" to="inplay-sports"><span><img
                                            src="@/assets/images/inplay.webp"></span>{{
                                    translatedLangData('inplay', 'Inplay') }}</router-link></li>
                            <li><router-link @click="closeModal()" to="/exchange-games"><span><img
                                            src="@/assets/images/exchange.webp"></span>{{
                                    translatedLangData('exchange', 'Exchange') }}</router-link></li>
                            <li><a @click="clickOnSportsBook()"><span><img src="@/assets/images/sportsBook.webp"></span>{{
                                    translatedLangData('sports-book', 'Sportsbook') }}</a></li>
                        </ul>
                    </div>
                    <div class="body-menu-category border-outer">
                        <h4>{{ translatedLangData('casinos', 'Casinos') }}</h4>
                        <ul>
                            <li><a href="casinos.html"><span><img src="@/assets/images/livecasinos.webp"></span>Live
                                    Casino</a></li>
                            <li><a href="#"><span><img src="@/assets/images/tableGames.webp"></span>Table Games</a></li>
                            <li><a href="#"><span><img src="@/assets/images/indianCas.webp"></span>Indian Casino</a>
                            </li>
                            <li><a href="#"><span><img src="@/assets/images/slotGames.webp"></span>Slot Games</a></li>
                            <li><a href="virtual-sports.html"><span><img
                                            src="@/assets/images/VirtualSports.webp"></span>Virtual Sports</a></li>
                            <li><a href="#"><span><img src="@/assets/images/lottery_games.webp"></span>Lottery Games</a>
                            </li>
                            <li><a href="#"><span><img src="@/assets/images/scratch.webp"></span>Scratch Games</a></li>
                            <li><a href="#"><span><img src="@/assets/images/shoting.webp"></span>Shooting Games</a></li>
                            <li><a href="#"><span><img src="@/assets/images/instantWin.webp"></span>Instant Win</a></li>
                            <li><a href="#"><span><img
                                            src="@/assets/images/lightning_roulette.webp"></span>Lightning</a>
                            </li>
                            <li><a href="#"><span><img src="@/assets/images/blackjacknew.webp"></span>Black Jack</a>
                            </li>
                            <li><a href="casinos.html"><span><img src="@/assets/images/AllCasinos.webp"></span>All
                                    Casino</a></li>
                        </ul>
                    </div>
                    <div v-if="sectionWiseGamesList && sectionWiseGamesList[0]?.gamesList && sectionWiseGamesList[0].gamesList.length != 0"
                        class="body-menu-category border-outer">
                        <h4>{{ translatedLangData('multiplayer', 'Multiplayer') }}</h4>
                        <ul>
                            <li v-for="(item, index) in sectionWiseGamesList[0].gamesList.slice(0, sectionWiseGamesList[0]?.gamesList.length < 3 ? sectionWiseGamesList[0]?.gamesList.length : 3)"
                                :key="index"><a @click="clickOnGamestButton(item)"><span><img :src="item?.image"
                                            :alt="item.game_name" loading="lazy"></span>{{ item.game_name }}</a></li>
                            <li><router-link @click="closeModal()"
                                    :to="{ name: 'games-category', params: { type: sectionWiseGamesList[0]?.slugName } }"><span><img
                                            src="@/assets/images/multiplayernew.webp"></span>{{
                                    translatedLangData('all', 'All') }}&nbsp;{{ translatedLangData('multiplayer',
                                    'Multiplayer') }}</router-link></li>
                        </ul>
                    </div>
                    <div class="body-menu-category border-outer">
                        <h4>{{ translatedLangData('sports', 'Sports') }}</h4>
                        <ul>
                            <li v-for="(sport, n_index) in non_custom_sports" :key="n_index"
                                v-show="checkInEventMange(sport.slug)"><router-link @click="closeModal()"
                                    :to="'sports/' + sport?.slug + '/' + sport?.id"><span>
                                        <img loading="lazy" v-if="sport.id == 4" src="@/assets/images/Cricket.webp"
                                            alt="">
                                        <img loading="lazy" v-if="sport.id == 1" src="@/assets/images/Football.webp"
                                            alt="">
                                        <img loading="lazy" v-if="sport.id == 2" src="@/assets/images/Tennis.webp"
                                            alt="">
                                    </span>{{ sport?.name }}</router-link>
                            </li>
                            <li><router-link @click="closeModal()"
                                    :to="{ name: 'racing-category', params: { type: 'HORSE_RACE' } }"><span><img
                                            src="@/assets/images/horse_racingnew.webp"></span>Horse Racing</router-link>
                            </li>
                            <li><router-link @click="closeModal()"
                                    :to="{ name: 'racing-category', params: { type: 'GREY_HOUND_RACING' } }"><span><img
                                            src="@/assets/images/greyhoundnew.webp"></span>Greyhound</router-link></li>
                            <li><a href="#"><span><img src="@/assets/images/rugbyMenuitems.webp"></span>Rugby Union</a>
                            </li>
                            <li><a href="#"><span><img src="@/assets/images/snookerMenuItems.webp"></span>Snooker</a>
                            </li>
                            <li><a href="all-sports.html"><span><img src="@/assets/images/allsports.webp"></span>All
                                    Sports</a></li>
                        </ul>
                    </div>
                    <div v-if="sectionWiseGamesList && sectionWiseGamesList[5]?.gamesList && sectionWiseGamesList[5].gamesList.length != 0"
                        class="body-menu-category border-outer">
                        <h4>{{ translatedLangData('esports', 'ESports') }}</h4>
                        <ul>
                            <li v-for="(item, index) in sectionWiseGamesList[5].gamesList.slice(0, sectionWiseGamesList[5]?.gamesList.length < 3 ? sectionWiseGamesList[5]?.gamesList.length : 3)"
                                :key="index"><a @click="clickOnGamestButton(item)"><span><img :src="item?.image"
                                            :alt="item.game_name" loading="lazy"></span>{{ item.game_name }}</a>
                            </li>
                            <li><router-link @click="closeModal()"
                                    :to="{ name: 'games-category', params: { type: sectionWiseGamesList[5]?.slugName } }"><span><img
                                            src="@/assets/images/all_esportsnew.webp"></span>{{
                                    translatedLangData('all', 'All') }}&nbsp;{{ translatedLangData('esports', 'ESports')
                                    }}</router-link>
                            </li>
                        </ul>
                    </div>
                    <div class="body-menu-category border-outer">
                        <h4>{{ translatedLangData('popular-games', 'Popular Games') }}</h4>
                        <ul>
                            <li><a href="#"><span><img src="@/assets/images/Aviator.webp"></span>Aviator</a></li>
                            <li><a href="#"><span><img src="@/assets/images/Monopoly.webp"></span>Big Baller</a></li>
                            <li><a href="#"><span><img src="@/assets/images/CrazyTime.webp"></span>Crazy Time</a></li>
                            <li><a href="#"><span><img src="@/assets/images/ROULETTE.webp"></span>Roulette EVO</a></li>
                            <li><a href="#"><span><img src="@/assets/images/AmericanRoulette.webp"></span>Roulette</a>
                            </li>
                            <li><a href="#"><span><img src="@/assets/images/DragonTiger.webp"></span>Dragon Tiger</a>
                            </li>
                            <li><a href="#"><span><img src="@/assets/images/AndarBahar.webp"></span>Andar Bahar</a></li>
                            <li><a href="#"><span><img src="@/assets/images/TeenPattiFaceOff.webp"></span>Teen Patti</a>
                            </li>
                        </ul>
                    </div>
                    <div v-if="sectionWiseGamesList && sectionWiseGamesList[6]?.gamesList && sectionWiseGamesList[6].gamesList.length != 0"
                        class="body-menu-category border-outer">
                        <h4>{{ translatedLangData('providers', 'Providers') }}</h4>
                        <ul>
                            <li v-for="(item, index) in sectionWiseGamesList[6].gamesList.slice(0, sectionWiseGamesList[6]?.gamesList.length < 3 ? sectionWiseGamesList[6]?.gamesList.length : 3)"
                                :key="index"><a @click="clickOnGamestButton(item)"><span><img :src="item?.image"
                                            :alt="item.game_name" loading="lazy"></span>{{ item.game_name }}</a></li>
                            <li><router-link @click="closeModal()"
                                    :to="{ name: 'games-category', params: { type: sectionWiseGamesList[6]?.slugName } }"><span><img
                                            src="@/assets/images/allproviders.webp"></span>{{ translatedLangData('all',
                                    'All') }}&nbsp;{{ translatedLangData('providers', 'Providers') }}</router-link>
                            </li>
                        </ul>
                    </div>
                    <div class="body-menu-category border-outer">
                        <h4>{{ translatedLangData('accessibility', 'Accessibility') }}</h4>
                        <ul>
                            <li><router-link @click="closeModal()" to="/my-markets"><span><img
                                            src="@/assets/images/mymarkets.webp"></span>{{
                                    translatedLangData('my-markets', 'My Markets') }}</router-link></li>
                            <li><a @click="getWalletGateway()"><span><img
                                            src="@/assets/images/quickWithdraw.webp"></span>{{
                                    translatedLangData('withdraw', 'Withdraw') }}</a></li>
                            <li v-if="siteSettings &&
                                    siteSettings?.social_link &&
                                    siteSettings?.social_link?.whatsappno1"><a
                                    :href="'//wa.me/' + siteSettings?.social_link?.whatsappno1"
                                    target="blank"><span><img src="@/assets/images/Whatsapp.webp"></span>{{
                                    translatedLangData('support', 'Support') }}</a></li>
                            <li><router-link @click="closeModal()" to="/settings"><span><img src="@/assets/images/setting.webp"></span>{{
                                    translatedLangData('settings', 'Settings') }}</router-link>
                            </li>
                        </ul>
                        <a data-bs-toggle="modal" data-bs-target="#logoutmodal" class="btn gradient-btn w-100 mt-3">{{
                                    translatedLangData('logout', 'Logout') }}</a>
                    </div>
                </div>
            </div>
        </div>
    </div>



    <StakeModal @close-stake-modal="closeStakeModal()" @success-modal="showSuccessModalFunc"
        @error-modal="showErrorModalFunc" />


    <ClickBetModal @success-modal="showSuccessModalFunc" @error-modal="showErrorModalFunc" />

    <CreditReference />
    <div class="modal fade modal-footer-sticky" ref="siteRulesModal" tabindex="-1" aria-labelledby="exampleModalLabel"
        aria-hidden="true">
        <RulesModal :siteRuleData='siteRuleData' />
    </div>
    <LogoutModal @close="closeModal()" @success-modal="showSuccessModalFunc" @error-modal="showErrorModalFunc" />

    <LockedBonus @success-modal="showSuccessModalFunc" @error-modal="showErrorModalFunc" />
    <WelcomBonus @success-modal="showSuccessModalFunc" @error-modal="showErrorModalFunc" />


    <!-- for games pop up -->

    <div ref="open_login_modal" style="display: none;" data-bs-toggle="modal" data-bs-target="#login">
    </div>

    <div ref="games_pop_1_modal" style="display: none;" data-bs-toggle="modal" data-bs-target="#static_games_pop_1">
    </div>

    <div ref="games_pop_2_modal" style="display: none;" data-bs-toggle="modal" data-bs-target="#static_games_pop_2">
    </div>

    <div ref="iframe_pop_modal_account" style="display: none;" data-bs-toggle="modal"
        data-bs-target="#iframe_pop_modal_account">
    </div>


    <div class="modal fade game-play-modal" data-bs-backdrop="static" data-bs-keyboard="false"
        id="iframe_pop_modal_account" tabindex="-1" aria-labelledby="iframePopModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-fullscreen" style="max-width: 100%;">
            <IFrameModal @close-modal="openIframeModal = false" @error-modal="showErrorModalFunc"
                @success-modal="showSuccessModalFunc" v-if="openIframeModal" />
        </div>
    </div>


</template>
<script>

import BonusInformation from './BonusInformation.vue';
import StakeModal from './StakeModal.vue';
import CreditReference from './CreditReference.vue';
import RulesModal from './RulesModal.vue';
import LogoutModal from './LogoutModal.vue'
import { AUTH_TOKEN } from '@/shared/constants/cookies-const';
import axios from 'axios';
import { setHeaders } from '@/shared/services/headers';
import ClickBetModal from './ClickBetModal.vue';
import { kFormatter } from '@/shared/utils/formatter';
import api from '../services/api';
import * as apiName from '../services/urls';
//import { Translator } from 'vue-google-translate';
//import {Translator} from "vue-gtranslate";
import { Modal } from 'bootstrap';
import { mapGetters } from 'vuex';
import * as types from '@/modules/bets/utils/constants'
import LockedBonus from '@/shared/components/header/LockedBonus.vue';
import WelcomBonus from '@/modules/bonus/components/WelcomBonus.vue';
import { getWalletGetwayServiceCall } from '@/shared/services/wallet-service';
import { setGameItem } from "@/shared/constants/shared-data";
import IFrameModal from "@/modules/games/components/IFrameModal.vue";

export default {
    name: "Account",
    inject: ['translatedLangData'],
    data() {
        return {
            siteRuleModal: null,
            userData: null,
            themeSelection: false,
            clickBetCheck: false,
            themeName: '',
            arrayOfCountries: null,
            siteRuleData: '',
            isMountedComponent: false,
            sectionsList: null,
            openIframeModal:false,
            non_custom_sports: JSON.parse(localStorage.getItem('sports_List')) ? JSON.parse(localStorage.getItem('sports_List')).non_custom : [],
        }
    },
    computed: {
        ...mapGetters({
            getNotification: 'getNotification'
        }),
        types() {
            return types;
        }
    },
    mounted() {
        if (this.getClickBetValue()) {
            this.clickBetCheck = true
        }
        this.sectionsList = this.$store?.getters?.gamesData.sections;
        this.isMountedComponent = true;
        this.siteRulesModal();
        this.userData = this.$store?.getters?.stateUser;
        window.authorization_token = `Bearer ${this.$cookies.get(AUTH_TOKEN)}`
        //for add on all APIs
        axios.defaults.headers.common['Authorization'] = `Bearer ${this.$cookies.get(AUTH_TOKEN)}`;
        if (localStorage.getItem('default_class')) {
            this.themeName = localStorage.getItem('default_class');
        }
        setHeaders();
        this.arrayOfCountries = [
            {
                code: 'en|af',
                title: 'Afrikaans',
            },
            {
                code: 'en|ar',
                title: 'Arabic',
            },
            {
                code: 'en|ko',
                title: 'Korean',
            },
            {
                code: 'en|lt',
                title: 'Lithuanian',
            },
        ]
    },
    components: {
        BonusInformation,
        StakeModal,
        CreditReference,
        RulesModal,
        ClickBetModal,
        LogoutModal,
        LockedBonus,
        WelcomBonus,
        IFrameModal
    },
    methods: {
        clickOnGamestButton(item) {
            if (this.checkIsLogin()) {
                this.openGamesPopup(item);
            } else {
                this.$refs.open_login_modal.click();
            }
        },
        openGamesPopup(gameItem) {
            debugger;
            setGameItem(gameItem);
            switch (this.userData().currency) {
                case 1:
                    if (gameItem.only_hkd == 1) {
                        this.$refs.games_pop_1_modal.click();
                    } else if (gameItem.only_hkd == 0) {
                        this.openIframeModal = true;
                        this.$refs.iframe_pop_modal_home.click();
                    }
                    break;
                case 2:
                    if (gameItem.only_hkd == 1) {
                        this.$refs.games_pop_1_modal.click();
                    } else if (gameItem.only_hkd == 0) {
                        this.$refs.games_pop_2_modal.click();
                    }
                    break;
                case 3:
                case 4:
                    this.openIframeModal = true;
                    this.$refs.iframe_pop_modal_home.click();
                    break;
            }
        },
        clickOnSportsBook() {
			let item = {
				provider: 'sports_book',
				game_name: 'Sports Book'
			}

			setGameItem(item);
			this.openIframeModalFunction();

		},
        openIframeModalFunction() {
			this.openIframeModal = true;
			this.$refs.iframe_pop_modal_account.click();
		},
        checkIsLogin() {
            return this.$store.getters.isAuthenticated;
        },
        checkInEventMange(type) {
            return this.eventMange ? this.eventMange?.filter(s => s.type == type).length != 0 ? false : true : true;
        },
        getWalletGateway() {
            getWalletGetwayServiceCall();
        },
        getGlobaLoader() {
            return this.$store.getters.globalLoader;
        },
        siteRulesModal() {
            this.siteRuleModal = new Modal(this.$refs.siteRulesModal);
        },
        betCheckChangeFunc() {
            if (this.clickBetCheck) {
                this.$refs.open_click_bet_value.click();
            }
            else {
                this.$store.dispatch('setClickBetValue', null);
            }
        },
        closeModal() {
            this.$refs.close_button.click();
        },
        closeStakeModal() {
            this.showStakeModal = false;
        },
        getClickBetValue() {
            return this.$store.getters.clickBetValue ? [...this.$store.getters.clickBetValue] : null;
        },

        getClickBetSelectedValue() {
            let inputValues = this.getClickBetValue();
            console.log("Input Values : ",inputValues);
            if (inputValues)
                return inputValues?.filter(item => item?.isSelected)[0]?.value;
        },
        getKFormatter(num) {
            return kFormatter(num);
        },

        setTheme(theme) {
            this.themeName = theme;
            localStorage.setItem('default_class', theme)
            document.documentElement.className = theme;
            this.themeSelection = false;
        },

        showErrorModalFunc(message) {
            this.$emit('error-modal', message);
        },
        showSuccessModalFunc(message) {
            this.$emit('success-modal', message);
        },

        getRules() {
            this.$store.dispatch('setGlobalLoader', true);
            let headers = {
                'Authorization': `Bearer ${this.$cookies.get(AUTH_TOKEN)}`
            }
            api.get(apiName.GET_SITE_RULES, { headers }).then(response => {
                this.$store.dispatch('setGlobalLoader', false);
                if (response) {
                    if (response.status == 200) {
                        if (response?.data?.status == 0) {
                            this.$emit('error-modal', response.data.debug[0]);
                        }
                        else {
                            this.siteRuleData = response.data.data.rules;
                            this.siteRuleModal.show();
                            // this.$emit('rulesModal');
                            // this.closeModal();
                        }
                    }
                }
            }).catch(error => {
                this.$store.dispatch('setGlobalLoader', false);
                if (error) {
                    this.$emit('error-modal', error[0]);
                }
            });
        },
    },

};
</script>