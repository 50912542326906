const BetsModule = () => import(/* webpackChunkName: "bets-module" */ './views/Module.vue');
const WinLoss = () => import(/* webpackChunkName: "win-loss" */ './views/WinLoss.vue');
const MyBets = () => import(/* webpackChunkName: "my-bets" */ './views/MyBets.vue');
const AccountStatement = () => import(/* webpackChunkName: "account-statement" */ './views/AccountStatement.vue');
const ProfitLoss = () => import(/* webpackChunkName: "profit-loss" */ './views/ProfitLoss.vue');

const BetsRoutes = {
    path: '/',
    component: BetsModule,
    children: [
        {
            path: 'win-loss',
            name: 'win-loss',
            component: WinLoss,
        },
        {
            path: 'my-bets/:type',
            name: 'my-bets',
            component: MyBets,
        },
        {
            path: 'account-statement',
            name: 'account-statement',
            component: AccountStatement,
        },
        {
            path: 'profit-loss',
            name: 'profit-loss',
            component: ProfitLoss,
        },
    ],
}

export default BetsRoutes;