<template>
   <!-- <header class="header-wapper">
      <nav class="navbar navbar-expand-lg">
         <div class="container-fluid">
            <div class="header-wapper-item">
               <div class="header-logo">
                  <div v-if="siteSettings" class="logo">
                     <router-link to="/"><img class="logo"
                           :src="siteSettings?.aws_url + siteSettings.storage_path?.domain_image + siteSettings.logo"
                           alt="logo" /></router-link>
                  </div>
                  <div class="offcanvas-sec">
                     <div class="offcanvas-manu">
                        <a class="btn btn-primary" data-bs-toggle="offcanvas" data-bs-target="#offcanvasExamplemenu"
                           aria-controls="offcanvasExamplemenu" role="button">
                           <img src="@/assets/images/bars.webp" alt="" loading="lazy" />
                        </a>
                        <SideMenu></SideMenu>
                     </div>

                  </div>
               </div>
               <div v-if="!checkIsLogin()" class="header-but">
                  <button class="thm-btn thm-btn-1" type="button" data-bs-toggle="modal" data-bs-target="#login">{{ translatedLangData('sign-in','Sign in')}}</button>
                  <button class="thm-btn thm-btn-1" type="button" data-bs-toggle="modal"
                     data-bs-target="#register1">{{ translatedLangData('register','Register')}}</button>
               </div>
               <div v-else class="header-but">
                  <ul>
                     <li>
                        <div class="dropdown header-wallet-but flex-between">
                           <div class="wallet-btn">
                              <img src="@/assets/images/wallet.webp" alt="" loading="lazy" />
                              <span class="header-btn-text">{{ stateUser.balance }}</span>
                           </div>
                           <div class="droopdown-arrow">
                              <img src="@/assets/images/down-arrow.webp" alt="" loading="lazy" />
                           </div>
                           <div class="dropdown-content">
                              <a @click="getWalletGateway" class="drop flex-between">
                                 <div class="Withdrawable">{{ translatedLangData('withdrawable','Withdrawable')}} (₹)</div>
                                 <div class="amount-text">0</div>
                              </a>
                              <a class="drop flex-between">
                                 <div class="Withdrawable">{{ translatedLangData('exposure','Exposure')}} (₹)</div>
                                 <div class="amount-text">{{ stateUser.exposure }}</div>
                              </a>
                           </div>
                        </div>
                     </li>
                     <li>
                        <router-link to="/wallet-deposit" class="header-wallet-but blinking">
                           <img src="@/assets/images/add.webp" alt="" loading="lazy" class="add-dep" />
                           <span class="header-btn-text">{{ translatedLangData('deposit','Deposit')}} </span>
                        </router-link>
                     </li>
                  </ul>
               </div>
            </div>
         </div>
      </nav>
   </header> -->

   <header v-if="showHeader.includes($route.name)" class="header-wapper">
      <div class="container">
         <nav class="navbar">
            <div class="left-header-menu">
               <button class="toggle-btn" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasLeft"
                  aria-controls="offcanvasLeft"><img src="@/assets/images/sidecheck.webp"></button>
               <router-link v-if="siteSettings" to="/"><img style="height: 40px;" class="logo"
                     :src="siteSettings?.aws_url + siteSettings?.storage_path?.domain_image + siteSettings?.logo"
                     alt="logo" /></router-link>

               <SideMenu></SideMenu>

            </div>
            <div v-if="!checkIsLogin()" class="header-right-menu">
               <a @click="demoUserLogin()" class="btn gradient-btn">{{ translatedLangData('demo', 'Demo') }}</a>
               <div class="header-dflex-btn">
                  <a href="#" data-bs-toggle="modal" data-bs-target="#login" class="btn transperent-btn"><span><img
                           src="@/assets/images/Loginnew.webp"></span>{{ translatedLangData('login', 'Login') }}</a>
                  <router-link to="/sign-up" class="btn gradient-btn"><span><img
                           src="@/assets/images/Signupnew.webp"></span> {{
      translatedLangData('signup', 'Signup') }}</router-link>
               </div>
            </div>
            <div v-else class="header-right-menu">
               <a @click="getWalletGateway()" class="btn thm-btn"><span><img src="@/assets/images/cash1.webp"></span> {{
      translatedLangData('deposit', 'Deposit') }}</a>
               <div class="header-portfolio-balance">
                  <div class="portfolio-dflex">
                     <label>{{ translatedLangData('liability', 'Liability') }} : </label>
                     <span class="error-color">0</span>
                  </div>
                  <div class="portfolio-dflex">
                     <label>{{ translatedLangData('balance', 'Balance') }} : </label>
                     <span>{{ stateUser.balance }}</span>
                  </div>
               </div>
               <div class="dropdown">
                  <a class="dropdown-toggle" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false"
                     href="#"><img src="@/assets/images/settings.webp"></a>
                  <ul class="dropdown-menu header-top-menu">
                     <li><span>{{ translatedLangData('username', 'Username') }}</span> <span>{{ stateUser.id }}</span>
                     </li>
                     <li><a @click="getWalletGateway()">{{ translatedLangData('wallet', 'Wallet') }}</a></li>
                     <li><a @click="getWalletGateway()">{{ translatedLangData('deposit', 'Deposit') }}</a></li>
                     <li><a @click="getWalletGateway()">{{ translatedLangData('withdraw', 'Withdraw') }}</a></li>
                     <li><a href="change-password.html">{{ translatedLangData('change-password', 'Change Password')
                           }}</a>
                     </li>
                     <li><a href="account-statement.html">{{ translatedLangData('account-statement', 'Account Statement')}}</a></li>
                     <li><a href="my-bets.html">{{ translatedLangData('my-bets', 'My Bets') }}</a></li>
                     <li><a href="casinobets.html">{{ translatedLangData('casino-reports', 'Casino Reports') }}</a></li>
                     <li><a href="profit-loss.html">{{ translatedLangData('profit-loss', 'Profit & Loss') }}</a></li>
                     <li><a href="stakebuttons.html">{{ translatedLangData('custom-stake-buttons','Custom Stake Buttons')}}</a></li>
                     <li><a href="index.html" class="btn logout-btn">{{ translatedLangData('logout','Logout')}}</a></li>
                  </ul>
               </div>
            </div>
         </nav>
      </div>
   </header>


   <header v-else class="header-wapper casino-header">
      <div class="container">
         <nav class="navbar" id="hide-header">
            <div class="left-header-menu">
               <button class="toggle-btn" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasLeft"
                  aria-controls="offcanvasLeft"><img src="@/assets/images/sidecheck.webp"></button>
               <SideMenu></SideMenu>
               <div class="main-row top-navbar-container">
                  <marquee class="MarqueeTxt" style="margin-right: 6px;">
                     <p> 👉👉GET 3% REFILL BONUS ON EVERY DEPOSIT FOR DEPOSIT CONTACT ON YOUR DEPOSIT AGENCY NUMBER
                        THROUGH WHATSAPP👈👈 To VERIFY YOUR BRANCH DEPOSIT/WITHDRAW NUMBER. CONTACT HELP DESK NO.+91
                        7741000000</p>
                  </marquee>
               </div>
            </div>
         </nav>
         <span class="toggle-icon">
            <i class="fa-solid fa-angle-up"></i>
         </span>
      </div>
   </header>
</template>

<script>

import { getWalletGetwayServiceCall } from "@/shared/services/wallet-service";
import SideMenu from "./SideMenu.vue";
import { mapGetters } from 'vuex';
import * as apiName from '@/shared/services/urls';
import api from '@/shared/services/api';
import axios from "axios";
import { setHeaders } from "@/shared/services/headers";
import { AUTH_TOKEN } from "@/shared/constants/cookies-const";

export default {
   name: "Header",
   inject: ['translatedLangData', 'recaptcha'],
   components: {
      SideMenu
   },
   data() {
      return {
         cricketFight_eventName: JSON.parse(localStorage.getItem('crick_fight_match')) ? JSON.parse(localStorage.getItem('crick_fight_match')).event_name : '',
         myContest_eventName: JSON.parse(localStorage.getItem('my_contest')) ? JSON.parse(localStorage.getItem('my_contest')).event_name : '',
         searchText: "",
         showHeader: ['Home', 'sports', 'sports-event-detail'],
         form: {
            type: '1',
            userid: '',
            password: '',
            code:''
         }
      }
   },
   created() { },
   computed: {
      ...mapGetters({
         siteSettings: 'siteSettings',
         stateUser: 'stateUser'
      })
   },
   methods: {
      demoUserLogin() {
         this.form.type = 1;
         this.form.userid = "demouser";
         this.form.password = "demopass";
         this.loginServiceCall(apiName.DEMO_LOGIN_API, "demo");
      },
      async loginServiceCall(url, type) {
         this.demoLoading = true;
         let recaptchaToken = await this.recaptcha();
         let data = {
            userid: this.form.userid.toString(),
            type:  this.form.type,
            password: this.form.password,
            recaptcha_token: recaptchaToken,
            cncode: this.form.code,
         };

         api
            .post(url, data)
            .then((response) => {
               this.demoLoading = false;
               if (response) {
                  if (response.status == 200) {
                     if (response?.data?.status == 0) {
                        this.showErrorModalFunc(response.data.debug[0]);
                     } else {
                        this.showSuccessModalFunc(response.data.message);
                        let userData = response.data.data;
                        this.$cookies.set(AUTH_TOKEN, userData.token);
                        this.$store.dispatch("setToken", `Bearer ${userData.token}`);
                        window.authorization_token = `Bearer ${this.$cookies.get(AUTH_TOKEN)}`;
                        //for add on all APIs
                        axios.defaults.headers.common[
                           "Authorization"
                        ] = `Bearer ${this.$cookies.get(AUTH_TOKEN)}`;
                        setHeaders();
                        this.setUserDataToStore(userData);
                        //this.$router.go();
                        location.reload();
                     }
                  }
               }
            })
            .catch((error) => {
               console.log("Error Login : ", error);
               this.demoLoading = false;
               if (error) {
                  this.showErrorModalFunc(error[0]);
               }
            });
      },

      showErrorModalFunc(message) {
			this.$emit('error-modal', message);
		},
		showSuccessModalFunc(message) {
			this.$emit('success-modal', message);
		},

      setUserDataToStore(userData) {
         let user = {
            balance: userData?.balance,
            currency: userData.currency,
            currency_slug: userData.currency_slug,
            last_login_at: userData.last_login_at,
            name: userData.name,
            role_id: userData.role_id,
            userid: userData.userid,
            exposure: userData.exposure,
            available_credits: userData.available_credits,
            id: userData.id,
            exp: userData.exp,
         };
         this.$store.dispatch("setUser", user);
         this.$store.dispatch("setChips", userData.chips);
         this.$store.dispatch("setEventManage", userData.event_manage);
      },

      getWalletGateway() {
         getWalletGetwayServiceCall();
      },
      checkForBackArrow() {
         let routeName = this.$route.name;
         let routeType = this.$route.params.type;
         switch (routeName) {
            //checking for show three dot menu
            case 'Home':
            case 'my-market':
               return true;
            case 'games':
               return routeType == 'GAMES';
            default:
               return false
         }
      },
      getTitle() {
         let routeName = this.$route.name;
         if (routeName == 'sports') {
            let routeType = this.$route.params.type;
            switch (routeType) {
               case 'cricket':
                  return 'Cricket';
               case 'soccer':
                  return 'Soccer';
               case 'tennis':
                  return 'Tennis';
               case 'ASSEMBLY_ELECTION':
                  return 'Assembly Election';
               case 'IPL_WINNER':
                  return 'IPL Winner';
               case 'HORSE_RACE':
                  return 'Horse Race'
               case 'GREY_HOUND':
                  return 'Grey Hound'
               default:
                  return 'Sports';
            }
         }
         else if (routeName == 'games') {
            let routeType = this.$route.params.type;
            switch (routeType) {
               case 'LIVE_CASINO':
                  return 'Live Casino';
               case 'WORLD_CASINO':
                  return 'World Casino';
            }
         }
         else if (routeName == 'virtual-sport') {
            return 'Virtual Sports';
         }
         else if (routeName == 'sports-book') {
            return 'Sportsbook';
         }
         else if (routeName == 'matka') {
            return 'Matka';
         }
         else if (routeName == 'notifications') {
            return 'Notifications';
         }
         else if (routeName == 'favourites') {
            return 'Favourites';
         }
         else if (routeName == 'wallet') {
            return 'Wallet';
         }
         else if (routeName == 'wallet-deposit') {
            return 'Wallet Deposit';
         }
         else if (routeName == 'deposit-payment-method') {
            return 'Wallet Payment Method';
         }
         else if (routeName == 'wallet-history') {
            return 'Wallet History'
         }
         else if (routeName == 'wallet-history-detail') {
            return 'Transaction Detail'
         }
         else if (routeName == 'payment-method' || routeName == 'wallet-withdraw') {
            return 'Withdraw Details'
         }
         else if (routeName == 'add-account') {
            return 'Account Details'
         } else if (routeName == 'CricketFight') {
            return 'CRICKET FIGHT'
         }
         else if (routeName == 'MatchDetails' || routeName == 'PlaceBet') {
            return this.cricketFight_eventName
         }
         else if (routeName == 'MyContests') {
            return 'MY CONTESTS'
         }
         else if (routeName == 'MyContestBetDetails') {
            return this.myContest_eventName
         }
         else if (routeName == 'racing-category' || routeName == 'racing-list-details' || routeName == 'racing-carousel') {
            let routeType = this.$route.params.type;
            switch (routeType) {
               case 'HORSE_RACE':
                  return 'Horse Race';
               case 'GREY_HOUND':
                  return 'Grey Hound';
               default:
                  return 'Racing';
            }
         }

      },
      callVoiceSearchFlag() {
         this.$emit('voice-search-flag')
      },
      checkIsLogin() {
         return this.$store?.getters?.isAuthenticated;
      },
      goToHome() {
         this.$router.push({ name: 'Home' });
      },
      searchIcon() {
         if (this.searchText) {
            this.$emit('search-filter', this.searchText)
         }
      }
   },
};
</script>