<template>
   <div class="login-sign-up">
      <div class="modal fade modal-footer-sticky " id="language_selection_pop_up" tabindex="-1"
         aria-labelledby="exampleModalLabel" aria-modal="true" role="dialog">
         <div class="modal-dialog  modal-dialog-centered">
            <div class="modal-content">
               <div class="modal-header lan-chang">
                  <div class="thm-heading">
                     <h3>{{ translatedLangData('change-language','Change your language')}}</h3>
                  </div>
                  <button type="button" ref="close_ref" class="btn-close" data-bs-dismiss="modal"></button>
               </div>
               <div class="modal-body">
                  <div class="langauage-content">
                     <div class="search-lang-input">
                        <input type="search" placeholder="search" v-model="searchTerm" class="form-control"> <button
                           class="btn link-btn"><img src="@/assets/images/search-grey-icon.webp" alt="search"></button>
                     </div>
                     <div class="custom-language-list">
                        <ul>
                           <li v-for="item in filteredLanguages" :key="item.lang_code"
                              :class="{ 'active': selectedValue.lang_code == item.lang_code }">
                              <div class="form-check" @click="this.selectedValue = item">
                                 <label class="form-check-label">
                                    {{ item.lang_name }}
                                 </label>
                              </div>
                           </li>

                        </ul>
                     </div>
                  </div>

                  <div class="withdraw-fix-sec continue-btn edit-btn">
                     <a class="thm-bor-but thm-but" data-bs-dismiss="modal"
                        aria-label="Close">{{ translatedLangData('cancel', 'Cancel') }}</a>
                     <a class="thm-but" @click="selectedlanguage">{{ translatedLangData('update', 'Update') }}</a>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>
<script>

import LanguageService from '@/shared/services/language-service.js'
export default {
   name: 'GamesPop1',
   inject: ['translatedLangData'],
   data() {
      return {
         selectedValue: '',
         searchTerm: ''
      }
   },
   computed: {
      siteSettings() {
         return this.$store.getters.siteSettings
      },
      languagesData() {
         return this.$store.getters.languagesData;
      },
      filteredLanguages() {
         if (this.searchTerm) {
            const searchTermLower = this.searchTerm.toLowerCase();
            return this.languagesData.filter(item => item.lang_name.toLowerCase().includes(searchTermLower));
         }
         return this.languagesData
      },

   },
   methods: {
      showErrorModalFunc(message) {
         this.$emit('error-modal', message);
      },
      showSuccessModalFunc(message) {
         this.$emit('success-modal', message);
      },
      async selectedlanguage() {
         if (this.selectedValue) {
            const aws_url_language = this.siteSettings?.aws_url + '/' + this.selectedValue?.lang_url
            this.$parent.langData = await LanguageService.getLanguageTranslationKey(aws_url_language);
            if (this.$parent.langData) {
               localStorage.setItem('selectedLanguageUrl', this.selectedValue.lang_url);
               this.showSuccessModalFunc('language updated succesfully');
               //document.querySelector('.btn.thm-bor-but[data-bs-dismiss="modal"]').click();
               this.$refs.close_ref.click();
            } else {
               localStorage.removeItem('selectedLanguageUrl');
               this.showErrorModalFunc('language data is not available')
            }
         }
      },

   }
}
</script>