const GamesModule = () => import(/* webpackChunkName: "games-module" */ './views/Module.vue');
const GamesCategory = () => import(/* webpackChunkName: "games-category" */ './views/GamesCategory.vue');
const Games = () => import(/* webpackChunkName: "games" */ './views/Games.vue');

const GamesRoutes = {
    path: '/',
    component: GamesModule,
    children: [
        {
            path: 'games',
            name: 'games',
            component: Games
        },
        {
            path: 'games-category/:type',
            name: 'games-category',
            component: GamesCategory
        }
    ],
    
}

export default GamesRoutes;