const state = {
    siteSettings: null,
    gamesData: null,
    domainName: (window.location.hostname.split('.').slice(-2)[0] ? window.location.hostname.split('.').slice(-2)[0].toUpperCase() : ''),
    clickBetValue: null,
    favouriteGames:null,
    globalLoader:false,
    depositAmount: 0,
    captchaData: null,
    notification:0,
    languagesData:null,
};
const getters = {
    siteSettings: state => state?.siteSettings,
    gamesData: state => state?.gamesData,
    domainName: state => state?.domainName,
    clickBetValue: state => state?.clickBetValue,
    favouriteGames:state => state?.favouriteGames,
    globalLoader:state => state?.globalLoader,
    depositAmount:state => state?.depositAmount,
    captchaData: state => state?.captchaData,
    getNotification: state => state?.notification,
    languagesData: state => state?.languagesData,
};
const actions = {
    setSettings({ commit }, siteSettings) {
        commit('setSettings', siteSettings);
    },
    setGamesData({ commit }, gamesData) {
        commit('setGamesData', gamesData);
    },
    setClickBetValue({ commit }, clickBetValue) {
        commit('setClickBetValue', clickBetValue);
    },
    setFavouriteGames({ commit }, favouriteGames) {
        commit('setFavouriteGames', favouriteGames);
    },
    setGlobalLoader({ commit }, globalLoader) {
        commit('setGlobalLoader', globalLoader);
    },
    setDepositAmount({ commit }, depositAmount) {
        commit('setDepositAmount', depositAmount);
    },
    setCaptchaData({ commit }, captchaData) {
        commit('setCaptchaData', captchaData);
    },
    setNotification({ commit }, notification) {
        commit('setNotification', notification);
    },
    setLanguagesData({ commit }, languagesData) {
        commit('setLanguagesData', languagesData);
    },
};
const mutations = {
    setSettings(state, siteSettings) {
        state.siteSettings = siteSettings;
    },
    setGamesData(state, gamesData) {
        state.gamesData = gamesData;;
    },
    setClickBetValue(state, clickBetValue) {
        state.clickBetValue = clickBetValue;
    },
    setFavouriteGames(state, favouriteGames) {
        state.favouriteGames = favouriteGames;
    },
    setGlobalLoader(state, globalLoader) {
        state.globalLoader = globalLoader;
    },
    setDepositAmount(state, depositAmount){
        state.depositAmount =  depositAmount
    },
    setCaptchaData(state, captchaData) {
        state.captchaData = captchaData;
    },
    setNotification(state) {
        state.notification = state.notification+1;
    },
    setLanguagesData(state, languagesData) {
        state.languagesData = languagesData;
    },
};
export default {
    state,
    getters,
    actions,
    mutations
};