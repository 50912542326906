import App from "./App.vue";
import router from "./router"
import store from "./store";
import VueCookies from 'vue3-cookies';
import { createApp } from 'vue'
import "./assets/css/owl.carousel.min.css";
import "./assets/css/bootstrap.min.css";
// import "./assets/css/chat.css";
// import "./assets/css/chatresponsive.css";

import "bootstrap";
import 'vue3-carousel/dist/carousel.css';
import VueAwesomePaginate from "vue-awesome-paginate";
// import the necessary css file
import "vue-awesome-paginate/dist/style.css";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
// import { VueRecaptchaPlugin } from 'vue-recaptcha'

//import Interceptors from '@/shared/services/Interceptors'

// Import one of the available themes

// Import Bootstrap and BootstrapVue CSS files (order is important)
//import 'bootstrap/dist/css/bootstrap.css'
//import 'bootstrap-vue/dist/bootstrap-vue.css'

// import "./assets/js/bootstrap.bundle.min.js";
// import "./assets/js/jquery.min.js";
// //import "./assets/js/owl.carousel.min.js";
import "vue-gtranslate/translator.css"
import "./assets/css/style1.css";
import "./assets/css/style.css";
import "./assets/css/responsive.css";
import { VueReCaptcha } from 'vue-recaptcha-v3'
import './registerServiceWorker'

import { library, dom } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { fas } from '@fortawesome/free-solid-svg-icons' 
library.add(fas)
dom.watch();

router.beforeEach((to, from) => {
    window.scrollTo({
        top: 0,
    });
})


const app = createApp(App);
app.use(store);
app.use(router);
app.use(VueCookies);
app.component("v-select", vSelect);
app.component("font-awesome-icon", FontAwesomeIcon)
app.use(VueReCaptcha, { siteKey: process.env.VUE_APP_CAPTCHA_SITEKEY,loaderOptions: {autoHideBadge: true} })
app.use(VueAwesomePaginate);
app.mount("#app");
// app.use(VueRecaptchaPlugin, {
//     v2SiteKey: '6Lfqat4nAAAAAC-mYM9SMi6VeZyGopYJoZR1QRqX',
//     v3SiteKey: '6Lfqat4nAAAAAPJUPXr_OJR2Q2N7WNziy2eIGgCg',
//   })
//app.config.productionTip = false;


// define global properties
app.config.globalProperties.fantasy_server = "fantasycricket";
app.config.globalProperties.click_server = "fantasy";


router.beforeEach((to, from) => {
    window.store.dispatch('setGlobalLoader',true);
    setTimeout(() => {
        window.store.dispatch('setGlobalLoader',false);
    }, 2000);
    window.store.dispatch('setSearchText','');
})
