<template>
    <div class="one-click-modal">
        <div class="modal fade" id="clickBetValue" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">

                        <h5 class="modal-title show-onclcik" v-if="!showAddValue" id="exampleModalLabel1">{{ translatedLangData('edit-one-click-bet-stake', 'Edit One Click Bet Stake')}}
                        </h5>
                        <h5 class="modal-title add-onclcik" v-else id="exampleModalLabel">{{ translatedLangData('one-click-bet-stake', 'One Click Bet Stake')}}</h5>

                        <button ref="close_btn" type="button" class="btn-cl" data-bs-dismiss="modal" aria-label="Close">
                            <img src="@/assets/images/click-close.webp" alt="" />
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="one-click-bet">
                            <div class="one-click-collapse">
                                <div class="content">
                                    <div class="d-flex">
                                        <div class="form-check form-switch switch">
                                            <input class="form-check-input testinput" v-model="clickBetCheck"
                                                @change="betCheckChangeFunc()" type="checkbox"
                                                id="flexSwitchCheckDefault" />
                                            <label class="form-check-label" for="flexSwitchCheckDefault">{{ translatedLangData('change-password', 'Edit One Click Bet Stake')}}One Click Bet</label>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="!showAddValue" class="stake-show">
                                    <div class="row m-0">
                                        <div class="col s12 one-click-setting">
                                            <div v-if="inputvalue" class="mt-10 ng-star-inserted">
                                                <a @click="selectInputValue(0)"
                                                    :class="{ 'select': inputvalue[0].isSelected }"
                                                    class="ng-star-inserted">{{ inputvalue[0].value }}</a>
                                                <a @click="selectInputValue(1)"
                                                    :class="{ 'select': inputvalue[1].isSelected }"
                                                    class="ng-star-inserted">{{ inputvalue[1].value }}</a>
                                                <a @click="selectInputValue(2)"
                                                    :class="{ 'select': inputvalue[2].isSelected }"
                                                    class="ng-star-inserted">{{ inputvalue[2].value }}</a>
                                                <a @click="selectInputValue(3)"
                                                    :class="{ 'select': inputvalue[3].isSelected }"
                                                    class="ng-star-inserted">{{ inputvalue[3].value }}</a>
                                                <div @click="showAddValue = true"
                                                    class="add-custom-stake mt-10 ng-star-inserted">
                                                    {{ translatedLangData('add-custom-stake', 'Add Custom stake')}}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div v-else class="edit-one-input">
                                    <div class="mt-10 ng-star-inserted">
                                        <form class="click-form ng-untouched ng-pristine ng-valid">
                                            <div class="row m-0">
                                                <div
                                                    class="click-input ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                    <div class="input-field col s12 ng-untouched ng-pristine ng-valid">
                                                        <input placeholder="Enter Stake Value" id="" type="number"
                                                            formcontrolname="chipValue" v-model="inputvalue[0].value"
                                                            class="ng-untouched ng-pristine ng-valid ng-star-inserted" />

                                                    </div>
                                                </div>
                                                <div
                                                    class="click-input ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                    <div class="input-field col s12 ng-untouched ng-pristine ng-valid">
                                                        <input placeholder="Enter Stake Value" id="" type="number"
                                                            formcontrolname="chipValue" v-model="inputvalue[1].value"
                                                            class="ng-untouched ng-pristine ng-valid ng-star-inserted" />

                                                    </div>
                                                </div>
                                                <div
                                                    class="click-input ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                    <div class="input-field col s12 ng-untouched ng-pristine ng-valid">
                                                        <input placeholder="Enter Stake Value" id="" type="number"
                                                            formcontrolname="chipValue" v-model="inputvalue[2].value"
                                                            class="ng-untouched ng-pristine ng-valid ng-star-inserted" />

                                                    </div>
                                                </div>
                                                <div
                                                    class="click-input ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                    <div class="input-field col s12 ng-untouched ng-pristine ng-valid">
                                                        <input placeholder="Enter Stake Value" id="" type="number"
                                                            formcontrolname="chipValue" v-model="inputvalue[3].value"
                                                            class="ng-untouched ng-pristine ng-valid ng-star-inserted" />

                                                    </div>
                                                </div>

                                            </div>
                                            <div class="flex-between-btn">
                                                <button @click="saveClickBetValue()" class="green btn btn-my"
                                                    id="liveToastBtn" type="button"><span
                                                        class="animate-btn">{{ translatedLangData('save', 'Save')}}</span></button>
                                                <a @click="closeBet()" class="red btn mr-10 btn-my"><span
                                                        class="animate-btn">{{ translatedLangData('cancel', 'Cancel')}}</span></a>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { kFormatter } from '@/shared/utils/formatter';
import { reactive } from 'vue';

export default {
    name: "ClickBetModal",
    inject: ['translatedLangData'],
    data() {
        return {
            inputvalue: null,
            showAddValue: false,
            clickBetCheck: false,
        }
    },
    mounted() {
        this.setClickBetValue()
        if (this.getClickBetValue()) {
            this.clickBetCheck = true
        }
    },
    methods: {
        getClickBetValue() {
            return this.$store.getters.clickBetValue ? [...this.$store.getters.clickBetValue] : null;
        },

        selectInputValue(index) {
            for (let i = 0; i < this.inputvalue.length; i++) {
                this.inputvalue[i].isSelected = false;
            }
            this.inputvalue[index].isSelected = true;
        },
        saveClickBetValue() {
            this.$store.dispatch('setClickBetValue', this.inputvalue);
            this.$emit('success-modal', 'Click bet value updated successfully.');
            this.closeBet()
        },

        closeBet() {
            this.setClickBetValue();
            this.showAddValue = false
        },

        betCheckChangeFunc() {
            if (this.clickBetCheck) {
                this.$refs.open_click_bet_value.click();
            }
            else {
                this.$store.dispatch('setClickBetValue', null);
            }
        },

        setClickBetValue() {
            this.inputvalue = [];
            if (this.getClickBetValue()) {
                this.inputvalue = reactive(this.getClickBetValue()).map(item => item);
                console.log("Input value data :", this.inputvalue);
            }
            else {
                this.inputvalue = [
                    {
                        value: 1000,
                        isSelected: true,
                    },
                    {
                        value: 2000,
                        isSelected: false,
                    },
                    {
                        value: 3000,
                        isSelected: false,
                    },
                    {
                        value: 4000,
                        isSelected: false,
                    }
                ]
            }
        }


        // getClickBetSelectedValue() {
        //     let inputValues = this.getClickBetValue();
        //     if (inputValues)
        //         return this.getKFormatter(inputValues?.filter(item => item.isSelected))[0]?.value;
        // },
        // getKFormatter(num) {
        //     return kFormatter(num);
        // },
    },

    emits: ['close', 'success-modal', 'error-modal'],
};
</script>
<style scoped>
.border-input {
    border: 2px solid !important;
}
</style>