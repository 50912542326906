<template>
    <div class="container">
        <div class="custumer-support-btn">
            <button class="btn support-btn"><img src="@/assets/images/customer-service.webp"> <span class="livebtn">24/7
                    Customer Support</span></button>
        </div>
        <div v-if="siteSettings?.user_journey" class="tutorials-section">
            <h4>{{ translatedLangData('tutorials', 'tutorials') }}</h4>
            <div class="custumer-support-btn">
                <button @click="chooseUserJourney(siteSettings?.user_journey?.register_video, 'How to Register')"
                    class="btn support-btn">
                    <span class="livebtn">{{ translatedLangData('sign-login', 'Signup/Login') }}</span>
                    <div class="angle-icon">
                        <img src="@/assets/images/angle.webp">
                        <img src="@/assets/images/angle.webp">
                    </div>
                </button>
            </div>
            <div class="custumer-support-btn">
                <button @click="chooseUserJourney(siteSettings?.user_journey?.deposit_video_e, 'Automatic Deposit')"
                    class="btn support-btn">
                    <span class="livebtn">{{ translatedLangData('automatic-deposit', 'Automatic Deposit') }}</span>
                    <div class="angle-icon">
                        <img src="@/assets/images/angle.webp">
                        <img src="@/assets/images/angle.webp">
                    </div>
                </button>
            </div>
            <div class="custumer-support-btn">
                <button @click="chooseUserJourney(siteSettings?.user_journey?.deposit_video_e, 'Manual Deposit')"
                    class="btn support-btn">
                    <span class="livebtn">{{ translatedLangData('manual-deposit', 'Manual Deposit') }}</span>
                    <div class="angle-icon">
                        <img src="@/assets/images/angle.webp">
                        <img src="@/assets/images/angle.webp">
                    </div>
                </button>
            </div>
            <div class="custumer-support-btn">
                <button @click="getWalletGateway()" class="btn support-btn">
                    <span class="livebtn">{{ translatedLangData('withdrawal', 'Withdrawal') }}</span>
                    <div class="angle-icon">
                        <img src="@/assets/images/angle.webp">
                        <img src="@/assets/images/angle.webp">
                    </div>
                </button>
            </div>
        </div>
        <div class="footer-bets-wrapper">
            <div v-if="siteSettings && siteSettings?.logo" class="footer-logo">
                <a><img :src="siteSettings?.aws_url + siteSettings?.storage_path?.domain_image + siteSettings?.logo"></a>
            </div>
            <div v-if="siteSettings?.apk" class="footer-logo">
                <a :href="siteSettings?.aws_url + siteSettings?.storage_path?.apk_path + siteSettings?.apk"
                    :download="siteSettings?.apk"><img src="@/assets/images/android-apk.webp"></a>
            </div>
            <div class="footer-logo">
                <a href="#"><img src="@/assets/images/betfairs.webp"></a>
            </div>
        </div>
        <div class="footer-brand-logo">
            <a class="brand-pay" @click="getWalletGateway()"><img src="@/assets/images/brand1.webp"></a>
            <a class="brand-pay" @click="getWalletGateway()"><img src="@/assets/images/brand2.webp"></a>
            <a class="brand-pay" @click="getWalletGateway()"><img src="@/assets/images/brand3.webp"></a>
            <a class="brand-pay" @click="getWalletGateway()"><img src="@/assets/images/brand4.webp"></a>
            <a class="brand-pay" @click="getWalletGateway()"><img src="@/assets/images/brand5.webp"></a>
            <a class="brand-pay" @click="getWalletGateway()"><img src="@/assets/images/brand6.webp"></a>
            <a class="brand-pay" @click="getWalletGateway()"><img src="@/assets/images/brand7.webp"></a>
            <a class="brand-pay" @click="getWalletGateway()"><img src="@/assets/images/brand8.webp"></a>
            <a class="brand-pay" @click="getWalletGateway()"><img src="@/assets/images/brand9.webp"></a>
            <a class="brand-pay" @click="getWalletGateway()"><img src="@/assets/images/brand10.webp"></a>
            <a class="brand-pay" @click="getWalletGateway()"><img src="@/assets/images/brand11.webp"></a>
            <a class="brand-pay" @click="getWalletGateway()"><img src="@/assets/images/brand12.webp"></a>
            <a class="brand-pay" @click="getWalletGateway()"><img src="@/assets/images/brand13.webp"></a>
            <a class="brand-pay" @click="getWalletGateway()"><img src="@/assets/images/brand14.webp"></a>
        </div>
        <Copyrights />
        <div class="footer-menu">
            <ul>
                <li><router-link :to="{ name: 'static', params: { type: types().CONTACT_US } }">Contact us</router-link></li>
                <li><router-link :to="{ name: 'static', params: { type: types().COOKIE_POLICY } }">Cookie Policy</router-link></li>
                <li><router-link :to="{ name: 'static', params: { type: types().FAQS } }">FAQs</router-link></li>
            </ul>
            <ul>
                <li><router-link :to="{ name: 'static', params: { type: types().TERM_CONDITIONS } }">Terms & Conditions</router-link></li>
                <li><router-link :to="{ name: 'static', params: { type: types().RESPONSIBLE_GAMING } }">Responsible Gambling</router-link></li>
                <li><a @click="getWalletGateway()">Payment methods</a></li>
            </ul>
        </div>
        <div v-if="siteSettings && siteSettings?.social_link" class="footer-social">
            <a v-if="siteSettings?.social_link?.facebook_link" :href="siteSettings?.social_link?.facebook_link"
                target="_blank"><img src="@/assets/images/Faceook.webp"></a>
            <a><img src="@/assets/images/Youtube.webp"></a>
            <a v-if="siteSettings?.social_link?.instagram_link" :href="siteSettings?.social_link?.instagram_link"
                target="_blank"><img src="@/assets/images/Instagram.webp"></a>
            <a><img src="@/assets/images/Twitter.webp"></a>
            <a v-if="siteSettings?.social_link?.telegram_link" :href="siteSettings?.social_link?.telegram_link"
                target="_blank"><img src="@/assets/images/Telegram.webp"></a>
        </div>
    </div>

    <div class="modal fade game-play-modal" @click="showUserJourneyVideo = false" id="show_user_journey_video"
        tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog ">
            <VideoModal v-if="showUserJourneyVideo" :title="choosedUserJourney ? choosedUserJourney.title : null"
                :link="choosedUserJourney ? choosedUserJourney.link : null" />
        </div>
    </div>

    <div ref="show_user_journey_video" style="display: none;" data-bs-toggle="modal"
        data-bs-target="#show_user_journey_video">
    </div>

</template>

<script>
import * as types from '@/modules/static/utils/constants';
import Copyrights from './Copyrights.vue';
import VideoModal from '../modal/VideoModal.vue';
import { getWalletGetwayServiceCall } from "@/shared/services/wallet-service";

export default {
    name: 'Contacts',
    inject: ['translatedLangData'],
    data() {
        return {
            choosedUserJourney: { link: null, title: null },
            showUserJourneyVideo: false,
        }
    },
    components: {
        Copyrights,
        VideoModal
    },
    computed: {
        siteSettings() {
            return this.$store.getters.siteSettings;
        },
        domainName() {
            return this.$store.getters.domainName;
        }
    },
    methods: {
        types() {
            return types;
        },
        chooseUserJourney(userJourneLink, title) {
            if (userJourneLink) {
                userJourneLink = this.siteSettings?.aws_url + this.siteSettings?.storage_path.user_journey_path + userJourneLink;
            }
            this.choosedUserJourney = { link: userJourneLink, title: title };
            //this.$bvModal.show('user_journey');
            this.showUserJourneyVideo = true;
            this.$refs.show_user_journey_video.click();
        },
        clickOnWalletDeposit() {
            this.$router.push({ path: '/wallet-deposit', query: { walletData: null } })
        },
        getWalletGateway() {
            getWalletGetwayServiceCall();
        },
    }
}
</script>

<style scoped>
.brand-pay{
    cursor: pointer;
}
</style>