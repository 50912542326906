<template>
    <div class="modal fade modal-footer-sticky" id="logoutmodal" tabindex="-1" aria-labelledby="exampleModalLabel"
        aria-hidden="true">
        <div class="modal-dialog onload-modal  exit-clickbet">
            <div class="modal-content">
                <div class="modal-header">
                    <div class="thm-heading">
                        <h3>{{ translatedLangData('exit','Exit')}}</h3>
                    </div>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body ">
                    <div class="modal-info">
                        <img src="@/assets/images/exit-clickbet-icon.webp" alt="">
                    </div>
                    <div class="thm-heading d-inline-block text-center m-auto w-100">
                        <h3>{{ translatedLangData('exit','Exit')}} {{domainName}}?</h3>
                        <p>{{ translatedLangData('quit-message','Are you sure you want to quit?')}}</p>
                    </div>
                </div>
                <div class="header-but  info-footer edit-stake-btn mt-0">
                    <div class="back-modal-footer ">
                        <button class="thm-bor-but thm-but" ref="close_btn" data-bs-dismiss="modal">{{ translatedLangData('no','No')}}</button>
                        <button @click="logoutBtnClick()" class="thm-but">{{ translatedLangData('yes','Yes')}}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios';
import { AUTH_TOKEN } from '@/shared/constants/cookies-const';
import api from '../services/api';
import * as apiName from '../services/urls';

export default {
    name: "LogoutModal",
    inject:['translatedLangData'],
    computed:{
        domainName() {
            return this.$store.getters.domainName;
        }
    },
    methods:{
        logoutBtnClick() {
            this.logoutServiceCall();
        },
        logoutServiceCall() {
            
            let headers = {
                'Authorization': `Bearer ${this.$cookies.get(AUTH_TOKEN)}`
            }
            api.get(apiName.LOGOUT_API, { headers }).then(response => {
                this.loading = false;
                if (response) {
                    if (response.status == 200) {
                        if (response?.data?.status == 0) {
                            this.$emit('error-modal',response.data.debug[0])
                        }
                        else {
                            this.$emit('success-modal',response?.data?.message);
                            // remove all cookie
                            this.$cookies.keys().forEach(cookie => this.$cookies.remove(cookie))
                            this.$store.dispatch('resetAllState');
                            localStorage.removeItem('is_offer');
                            this.$router.push({name:'Home'});
                            this.$refs.close_btn.click();
                            this.$emit('close');
                            //location.reload();
                        }
                    }
                }
            }).catch(error => {
                this.loading = false;
                if (error) {
                    this.$emit('error-modal',error[0]);
                }
            });
        },
    },
    emits: ['close', 'success-modal', 'error-modal'],
};
</script>